<template>
    <div>Esporta Tipologie Costituzione Società</div>
</template>

<script>
 import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

 export default {
     mounted() {
         this.$store.dispatch(SET_BREADCRUMB, [
             { title: "Impostazioni", route: "/settings" },
             { title: "Tipologie Costituzione Società", route: "/settings/statute-type/index" },
             { title: "Esporta Tipologie Costituzione Società" }
         ]);
     }
 };
</script>
